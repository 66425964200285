// extracted by mini-css-extract-plugin
export var column = "ControlPlaneRedirectModal__column__CYtVF";
export var flex = "ControlPlaneRedirectModal__flex__RIUHY";
export var flexColumn = "ControlPlaneRedirectModal__flexColumn__qxJIY";
export var formInput = "ControlPlaneRedirectModal__formInput__Qb6v2";
export var formRowMultiple = "ControlPlaneRedirectModal__formRowMultiple__DrZKf";
export var gap1 = "ControlPlaneRedirectModal__gap1__cehxl";
export var gap2 = "ControlPlaneRedirectModal__gap2__UUMGv";
export var gap3 = "ControlPlaneRedirectModal__gap3__Ck2ZJ";
export var gap4 = "ControlPlaneRedirectModal__gap4__OYcDR";
export var gap5 = "ControlPlaneRedirectModal__gap5__J_2w7";
export var row = "ControlPlaneRedirectModal__row__DoFK0";