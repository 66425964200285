// extracted by mini-css-extract-plugin
export var column = "RedirectModalTitle__column__w4k_r";
export var flex = "RedirectModalTitle__flex__jFUUM";
export var flexColumn = "RedirectModalTitle__flexColumn__LAiDR";
export var gap1 = "RedirectModalTitle__gap1__JHAAE";
export var gap2 = "RedirectModalTitle__gap2__hxxtW";
export var gap3 = "RedirectModalTitle__gap3__vd5fv";
export var gap4 = "RedirectModalTitle__gap4__FmIKU";
export var gap5 = "RedirectModalTitle__gap5__oQxwz";
export var heading = "RedirectModalTitle__heading__HM5dL";
export var row = "RedirectModalTitle__row__XP53w";