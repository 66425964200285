// extracted by mini-css-extract-plugin
export var column = "RedirectModalForm__column__KSjcP";
export var flex = "RedirectModalForm__flex__wDDYG";
export var flexColumn = "RedirectModalForm__flexColumn__nNi0y";
export var form = "RedirectModalForm__form__qDOkn";
export var gap1 = "RedirectModalForm__gap1__nb3ZW";
export var gap2 = "RedirectModalForm__gap2__ZqOlt";
export var gap3 = "RedirectModalForm__gap3__W0rc5";
export var gap4 = "RedirectModalForm__gap4__Gvzu5";
export var gap5 = "RedirectModalForm__gap5__cI5tE";
export var row = "RedirectModalForm__row__a6UcG";