// extracted by mini-css-extract-plugin
export var column = "RedirectModalFormCell__column__RvXTX";
export var flex = "RedirectModalFormCell__flex__xiuI2";
export var flexColumn = "RedirectModalFormCell__flexColumn__NCSA8";
export var formRow = "RedirectModalFormCell__formRow__Puwhr";
export var formRowErrorMessage = "RedirectModalFormCell__formRowErrorMessage__Z8Ssg";
export var formRowTitle = "RedirectModalFormCell__formRowTitle__fJWEh";
export var formRowTitleError = "RedirectModalFormCell__formRowTitleError__3xPeD";
export var formRowValue = "RedirectModalFormCell__formRowValue__ptkh7";
export var gap1 = "RedirectModalFormCell__gap1__jpTrC";
export var gap2 = "RedirectModalFormCell__gap2__uPAb9";
export var gap3 = "RedirectModalFormCell__gap3__oigtD";
export var gap4 = "RedirectModalFormCell__gap4__Tji1u";
export var gap5 = "RedirectModalFormCell__gap5__ap2DS";
export var row = "RedirectModalFormCell__row__SZLPI";
export var wrapper = "RedirectModalFormCell__wrapper__IhKR2";