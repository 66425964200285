// extracted by mini-css-extract-plugin
export var backgroundImageLeft = "RedirectModal__backgroundImageLeft__NTVKF";
export var backgroundImageRight = "RedirectModal__backgroundImageRight__JmgH7";
export var closeButton = "RedirectModal__closeButton__w5ULv";
export var column = "RedirectModal__column__AtOh1";
export var credentialsNote = "RedirectModal__credentialsNote__uw3fp";
export var errorMessage = "RedirectModal__errorMessage__WhLyZ";
export var flex = "RedirectModal__flex__dJRK2";
export var flexColumn = "RedirectModal__flexColumn__nzEJd";
export var gap1 = "RedirectModal__gap1__g1e4Q";
export var gap2 = "RedirectModal__gap2__BmU_7";
export var gap3 = "RedirectModal__gap3__odYwL";
export var gap4 = "RedirectModal__gap4__wCk8U";
export var gap5 = "RedirectModal__gap5__IE8pv";
export var logo = "RedirectModal__logo__tgnIz";
export var logoRow = "RedirectModal__logoRow__PUu94";
export var logoSeparator = "RedirectModal__logoSeparator__jhDf4";
export var modalBody = "RedirectModal__modalBody__SdeXS";
export var modalBodyWrapper = "RedirectModal__modalBodyWrapper__noQkB";
export var modalFooter = "RedirectModal__modalFooter__El1VP";
export var modalHeader = "RedirectModal__modalHeader__gdFzb";
export var modalRoot = "RedirectModal__modalRoot__pCV9Z";
export var row = "RedirectModal__row__dTZKh";
export var subtitle = "RedirectModal__subtitle__j3O0t";