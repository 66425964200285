// extracted by mini-css-extract-plugin
export var column = "FreeMonthsExplainer__column__cDFQC";
export var flex = "FreeMonthsExplainer__flex__ALawN";
export var flexColumn = "FreeMonthsExplainer__flexColumn__hKOqN";
export var gap1 = "FreeMonthsExplainer__gap1__M3cWd";
export var gap2 = "FreeMonthsExplainer__gap2__RPenZ";
export var gap3 = "FreeMonthsExplainer__gap3__jxqPq";
export var gap4 = "FreeMonthsExplainer__gap4__al0KJ";
export var gap5 = "FreeMonthsExplainer__gap5__Z6SCV";
export var row = "FreeMonthsExplainer__row__cyYhC";
export var tooltipContentUl = "FreeMonthsExplainer__tooltipContentUl__mw0E6";
export var tooltipInnerContainer = "FreeMonthsExplainer__tooltipInnerContainer__RKuDo";
export var tooltipWrapper = "FreeMonthsExplainer__tooltipWrapper__ciPdZ";